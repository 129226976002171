import {
  htmlToElement,
  mergeSettings
} from './Helpers.js'

import {
  CARD_CLASS,
  EVENT_CARD_TAP,
  EVENT_CARD_PAN,
  EVENT_CARD_PAN_END,
  EVENT_CARD_PAN_SUCCESS,
  EVENT_CARD_PAN_RESET
} from './constants.js'

import Hammer from 'hammerjs'

function generateDom (dom) {
  const container = htmlToElement(`<div class="${CARD_CLASS}"></div>`)
  container.appendChild(dom)

  return container
}

class Card {
  constructor (options) {
    const defaults = {
      dom: null,
      active: false,
      lazyloadSelector: '.lazy'
    }
    const {
      dom,
      active
    } = mergeSettings(options, defaults)

    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
    this.toggleStatus = this.toggleStatus.bind(this)
    this.onTap = this.onTap.bind(this)
    this.onPan = this.onPan.bind(this)
    this.onPanEnd = this.onPanEnd.bind(this)
    this.onPanReset = this.onPanReset.bind(this)
    this.onPanIsNext = this.onPanIsNext.bind(this)
    this.applyStyle = this.applyStyle.bind(this)
    this.resetStyle = this.resetStyle.bind(this)

    this.dom = generateDom(dom)
    this.isnext = false
    this.active = active
    this.scale = 0.97
    if (!this.dom) {
      console.warn('%o is not a dom element. Can\'t get image dom.', dom)
    }

    if (active) {
      this.activate()
    } else {
      this.deactivate()
    }

    // Initialize style and initial listeners
    this.applyStyle()
  }

  initListeners () {
    if (this.dom) {
      if (this.isnext) {
        document.addEventListener(EVENT_CARD_PAN, this.onPanIsNext)
        document.addEventListener(EVENT_CARD_PAN_RESET, this.onPanReset)
        document.addEventListener(EVENT_CARD_PAN_END, this.onPanEnd)
      } else {
        this.handle()
      }
    }
  }

  removeListeners () {
    if (this.dom) {
      document.removeEventListener(EVENT_CARD_PAN, this.onPanIsNext)
      document.removeEventListener(EVENT_CARD_PAN_RESET, this.onPanReset)
      document.removeEventListener(EVENT_CARD_PAN_END, this.onPanEnd)
    }
  }

  handle () {
    if (this.dom) {
      this.container = this.dom.parentNode
      this.dom.style.transition = 'transform 100ms linear'
      this.dom.style.transform = 'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
      this.dom.style.opacity = 1

      // destroy previous Hammer instance, if present
      this.hammer && this.hammer.destroy()

      // listen for tap and pan gestures on card
      this.hammer = new Hammer(this.dom)
      this.hammer.add(new Hammer.Tap())
      this.hammer.add(new Hammer.Pan({
        position: Hammer.position_ALL, threshold: 0
      }))

      // pass events data to custom callbacks
      this.hammer.on('tap', (e) => { this.onTap(e) })
      this.hammer.on('pan', (e) => { this.onPan(e) })
    }
  }

  onPanIsNext (e) {
    const {
      propX
    } = e.detail

    const maxPropX = Math.abs(propX) > 1 ? 1 : Math.abs(propX)
    this.scale = (95 + (5 * maxPropX)) / 100

    if (this.dom) {
      this.dom.style.opacity = maxPropX
      this.dom.style.transition = null
      this.dom.style.transform = `translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(${this.scale})`
    }
  }

  onPanReset (e) {
    if (this.dom) {
      this.dom.style.transition = 'opacity 100ms linear, transform 100ms linear'
      this.dom.style.opacity = 0
      this.dom.style.transform = `translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(${this.scale})`
    }
  }

  onPanEnd (e) {
    if (this.dom) {
      this.dom.style.transition = 'opacity 100ms linear, transform 100ms linear'
      this.dom.style.transform = `translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(${this.scale})`
    }
  }

  onPan (e) {
    if (!this.isPanning) {
      this.isPanning = true

      // remove transition properties
      this.dom.style.transition = null

      // get card coordinates in pixels
      const style = window.getComputedStyle(this.dom)
      const mx = style.transform.match(/^matrix\((.+)\)$/)
      this.startPosX = mx ? parseFloat(mx[1].split(', ')[4]) : 0
      this.startPosY = mx ? parseFloat(mx[1].split(', ')[5]) : 0

      // get card bounds
      const bounds = this.dom.getBoundingClientRect()

      // get finger position on card, top (1) or bottom (-1)
      this.isDraggingFrom =
              (e.center.y - bounds.top) > this.dom.clientHeight / 2 ? -1 : 1
    }

    // calculate new coordinates
    let posX = e.deltaX + this.startPosX
    let posY = e.deltaY + this.startPosY

    // get ratio between swiped pixels and the axes
    const propX = e.deltaX / this.container.clientWidth
    const propY = e.deltaY / this.container.clientHeight

    // get swipe direction, left (-1) or right (1)
    const dirX = e.deltaX < 0 ? -1 : 1

    // calculate rotation, between 0 and +/- 45 deg
    const deg = this.isDraggingFrom * dirX * Math.abs(propX) * 45

    // move card
    this.dom.style.transform = 'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg) rotateY(0deg) scale(1)'

    document.dispatchEvent(new CustomEvent(EVENT_CARD_PAN, {
      detail: {
        propX: propX,
        propY: propY,
        direction: e.direction
      }
    }))

    if (e.isFinal) {
      this.isPanning = false
      let successful = false

      // set back transition properties
      this.dom.style.transition = 'transform 200ms ease-out'

      // check threshold
      if (propX > 0.25 && e.direction === Hammer.DIRECTION_RIGHT) {
        successful = true
        // get right border position
        posX = this.container.clientWidth
      } else if (propX < -0.25 && e.direction === Hammer.DIRECTION_LEFT) {
        successful = true
        // get left border position
        posX = -(this.container.clientWidth + this.dom.clientWidth)
      } else if (propY < -0.25 && e.direction === Hammer.DIRECTION_UP) {
        successful = true
        // get top border position
        posY = -(this.container.clientHeight + this.dom.clientHeight)
      } else if (propY > 0.25 && e.direction === Hammer.DIRECTION_DOWN) {
        successful = true
        // get bottom border position
        posY = this.container.clientHeight + this.dom.clientHeight
      }
      document.dispatchEvent(new CustomEvent(EVENT_CARD_PAN_END, {
        detail: {
          direction: e.direction,
          posX: posX,
          posY: posY
        }
      }))
      if (successful) {
        // throw card in the chosen direction
        this.dom.style.transform =
                'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
        // wait transition end
        document.dispatchEvent(new CustomEvent(EVENT_CARD_PAN_SUCCESS, {
          detail: {
            direction: e.direction,
            posX: posX,
            posY: posY
          }
        }))

        setTimeout(() => {
          this.deactivate()
        }, 100)
      } else {
        // reset cards position
        this.dom.style.transform =
                'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
        this.dom.style.opacity = 1
        document.dispatchEvent(new CustomEvent(EVENT_CARD_PAN_RESET, {
          detail: {
            reset: true
          }
        }))
      }
    }
  }

  onTap (e) {
    // get finger position on card
    const propX = (e.center.x - e.target.getBoundingClientRect().left) / e.target.clientWidth

    // get degree of Y rotation (+/-15 degrees)
    const rotateY = 15 * (propX < 0.05 ? -1 : 1)

    document.dispatchEvent(new CustomEvent(EVENT_CARD_TAP, {
      detail: {
        propX: propX,
        rotation: rotateY
      }
    }))

    // change the transition property
    this.dom.style.transition = 'transform 100ms ease-out'

    if (this.isPanning) {
      // rotate
      this.dom.style.transform =
              'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(' + rotateY + 'deg) scale(1)'

      // wait transition end
      setTimeout(() => {
        // reset transform properties
        this.dom.style.transform =
                'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
      }, 100)
    } else {
      this.dom.style.transform =
              'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(0.98)'
      setTimeout(() => {
        // reset transform properties
        this.dom.style.transform =
                'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
      }, 100)
    }
  }

  activate (force) {
    if (force || !this.active) {
      this.active = true
      this.isnext = false
      this.applyStyle()
      this.initListeners()
    }
  }

  deactivate (force) {
    if (force || this.active) {
      this.active = false
      this.isnext = false
      this.applyStyle()
      this.removeListeners()
    }
  }

  toggleStatus () {
    this.active = !this.active
  }

  applyStyle () {
    if (this.dom) {
      if (this.active === true) {
        this.dom.classList.add(`${CARD_CLASS}--active`)
        this.dom.classList.remove(`${CARD_CLASS}--inactive`)
        this.dom.style.opacity = 1
      } else {
        this.dom.classList.remove(`${CARD_CLASS}--active`)
        this.dom.classList.add(`${CARD_CLASS}--inactive`)
        setTimeout(() => {
          this.resetStyle()
        }, 200)
      }
    }
  }

  resetStyle () {
    if (this.active === false) {
      this.dom.transition = null
      this.dom.style.opacity = 0
      this.dom.style.transform = 'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
    }
  }

  set container (dom) {
    this._container = dom
  }
  get container () {
    return this._container
  }

  set dom (dom) {
    this._dom = dom
  }
  get dom () {
    return this._dom
  }

  set active (value) {
    this._active = Boolean(value)
  }
  get active () {
    return this._active
  }

  set isnext (value) {
    this._isnext = Boolean(value)
    if (this._isnext) {
      this.initListeners()
    } else {
      this.removeListeners()
    }
  }
  get isnext () {
    return Boolean(this._isnext)
  }
}

export default Card
