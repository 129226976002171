import './style.scss'

import Hammer from 'hammerjs'
import TouchEmulator from 'hammer-touchemulator'

import {
  TENDER_CLASS,
  DIRECTION_RIGHT,
  DIRECTION_LEFT,
  INACTIVE_CLASS,
  ACTIVE_CLASS,
  EVENT_CARD_PAN,
  EVENT_CARD_PAN_END,
  EVENT_CARD_PAN_SUCCESS,
  EVENT_CARD_PAN_RESET,
  EVENT_CARD_TAP
} from './modules/constants.js'

import {
  mergeSettings,
  isDomNode
} from './modules/helpers.js'

import Card from './modules/Card.js'

const getNewCard = (dom) => {
  if (!dom || !isDomNode(dom)) {
    return null
  }

  return new Card(
    {
      dom: dom
    })
}

const getInitializedCards = (selector) => {
  const images = document.querySelectorAll(selector)
  const result = []
  let i = -1
  while (++i < images.length) {
    const image = getNewCard(images[i].cloneNode(true))
    if (image) {
      result.push(image)
    }
  }
  return result
}

class Tender {
  constructor (options) {
    const defaults = {
      containerSelector: '.Tender',
      imageSelector: '.Tender img',
      lazyloadSelector: '.lazy',
      direction: DIRECTION_RIGHT,
      copy: true,
      active: true,
      index: 0,
      toucheEmulator: true,
      onActivate: (currentCard, index) => {
      },
      onDeactivate: (currentCard, index) => {
      },
      onChange: (currentCard, previousCard, index) => {
      }
    }
    const {
      imageSelector,
      containerSelector,
      direction,
      active,
      index,
      copy,
      toucheEmulator,
      onActivate,
      onDeactivate,
      onChange
    } = mergeSettings(options, defaults)

    this.updateNextCardByDirection = this.updateNextCardByDirection.bind(this)
    this.updateCurrentCardByDirection = this.updateCurrentCardByDirection.bind(this)
    this.keyHandler = this.keyHandler.bind(this)
    this.onPan = this.onPan.bind(this)
    this.onPanEnd = this.onPanEnd.bind(this)
    this.onPanSuccess = this.onPanSuccess.bind(this)
    this.onPanReset = this.onPanReset.bind(this)
    this.onActivate = onActivate
    this.onDeactivate = onDeactivate
    this.onChange = onChange

    this.current = undefined
    this.next = undefined
    this.direction = direction
    this._container = document.querySelector(containerSelector)
    this._oldDirection = 1
    this._oldPropXSign = true
    if (!this._container) {
      console.warn(`\nModule: Tender.js\nWarning: No container dom node found in document.\nCause: No containerSelector provided.\nResult: Tender may not work.`)
    }
    this._container.classList.add(TENDER_CLASS)
    this._cardSelector = imageSelector

    this._cards = getInitializedCards(imageSelector, copy)
    this._cards.forEach(card => this._container.appendChild(card.dom))

    this.index = index
    if (active) {
      this.activate()
      this.current = this._cards[this.index]
      this.next = this._cards[this.index + 1]
    } else {
      this.deactivate()
    }
    if (toucheEmulator === true) {
      TouchEmulator()
    }
  }

  activate (force) {
    if (force || !this._active) {
      const transition = this._container.style.transition

      this._container.style.transition = 'opacity 0.25s ease-in-out'
      this._container.style.opacity = 1
      this._container.classList.remove(`${TENDER_CLASS}${INACTIVE_CLASS}`)
      this._container.classList.add(`${TENDER_CLASS}${ACTIVE_CLASS}`)
      setTimeout(() => {
        this._container.style.transition = transition
        this._active = true
        this.initListeners()
        this.onActivate(this.current, this.index)
      }, 250)
    }
  }

  deactivate (force) {
    if (force || this._active) {
      const transition = this._container.style.transition

      this._container.style.transition = 'opacity 0.25s ease-in-out'
      this._container.style.opacity = 0
      setTimeout(() => {
        this._container.style.transition = transition
        this._active = false
        this.removeListeners()
        this._container.classList.remove(`${TENDER_CLASS}${ACTIVE_CLASS}`)
        this._container.classList.add(`${TENDER_CLASS}${INACTIVE_CLASS}`)
        this.onDeactivate(this.current, this.index)
      }, 250)
    }
  }

  toggleActive () {
    this._active = !this._active
  }

  updateCurrentCardByDirection (propX) {
    if (propX < 0) {
      if (this.direction === DIRECTION_LEFT) {
        this.current = this.index + 1 > this._cards.length - 1 ? this._cards[0] : this._cards[this.index + 1]
      } else {
        this.current = this.index - 1 < 0 ? this._cards[this._cards.length - 1] : this._cards[this.index - 1]
      }
    }
    if (propX > 0) {
      if (this.direction === DIRECTION_LEFT) {
        this.current = this.index - 1 < 0 ? this._cards[this._cards.length - 1] : this._cards[this.index - 1]
      } else {
        this.current = this.index + 1 > this._cards.length - 1 ? this._cards[0] : this._cards[this.index + 1]
      }
    }
  }

  updateNextCardByDirection (propX) {
    this.next && this.next.resetStyle()
    if (propX < 0) {
      if (this.direction === DIRECTION_LEFT) {
        this.next = this.index + 1 > this._cards.length - 1 ? this._cards[0] : this._cards[this.index + 1]
      } else {
        this.next = this.index - 1 < 0 ? this._cards[this._cards.length - 1] : this._cards[this.index - 1]
      }
    }
    if (propX > 0) {
      if (this.direction === DIRECTION_LEFT) {
        this.next = this.index - 1 < 0 ? this._cards[this._cards.length - 1] : this._cards[this.index - 1]
      } else {
        this.next = this.index + 1 > this._cards.length - 1 ? this._cards[0] : this._cards[this.index + 1]
      }
    }
  }

  /* ------------------------------------- LISTENERS */
  initListeners () {
    document.addEventListener(EVENT_CARD_PAN, this.onPan)
    document.addEventListener(EVENT_CARD_PAN_END, this.onPanEnd)
    document.addEventListener(EVENT_CARD_PAN_SUCCESS, this.onPanSuccess)
    document.addEventListener(EVENT_CARD_PAN_RESET, this.onPanReset)
    document.addEventListener(EVENT_CARD_PAN_RESET, this.onPanReset)
    document.addEventListener(EVENT_CARD_TAP, this.onTap)
    document.addEventListener('keyup', this.keyHandler)
  }

  removeListeners () {
    document.removeEventListener(EVENT_CARD_PAN, this.onPan)
    document.removeEventListener(EVENT_CARD_PAN_END, this.onPanEnd)
    document.removeEventListener(EVENT_CARD_PAN_SUCCESS, this.onPanSuccess)
    document.removeEventListener(EVENT_CARD_PAN_RESET, this.onPanReset)
    document.removeEventListener(EVENT_CARD_PAN_RESET, this.onPanReset)
    document.removeEventListener(EVENT_CARD_TAP, this.onTap)
    document.removeEventListener('keyup', this.keyHandler)
  }

  onPan (e) {
    const {
      propX
    } = e.detail

    this._propX = propX
    const propXSign = propX > 0
    if (propXSign !== this._oldPropXSign || this._oldPropXSign === undefined) {
      this._oldPropXSign = propXSign
      this.updateNextCardByDirection(propX)
    }
  }

  onPanReset (e) {
    this._oldPropXSign = undefined
  }

  onPanEnd (e) {
    this._oldPropXSign = undefined
  }

  onPanSuccess (e) {
    const {
      direction
    } = e.detail

    if (direction) {
      if (direction === Hammer.DIRECTION_LEFT) {
        const previous = this.current
        this.goPrev()
        this.onChange(this.current, previous, this.index)
      } else if (direction === Hammer.DIRECTION_RIGHT) {
        const previous = this.current
        this.goNext()
        this.onChange(this.current, previous, this.index)
      } else if (direction === Hammer.DIRECTION_UP) {
        this.deactivate()
      } else if (direction === Hammer.DIRECTION_DOWN) {
        this.deactivate()
      }
    }
  }

  onTap (e) {
  }

  /* ------------------------------------- NAVIGATION */
  keyHandler (e) {
    switch (e.key) {
      case 'ArrowLeft':
        if (this.current) {
          e.preventDefault()
          this.goPrev()
        }
        break
      case 'ArrowRight':
        if (this.current) {
          e.preventDefault()
          this.goNext()
        }
        break
      case 'Escape':
        this.reset()
        break
    }
  }

  goNext () {
    this.updateCurrentCardByDirection(1)
  }

  goPrev () {
    this.updateCurrentCardByDirection(-1)
  }

  reset () {
  }

  /* ------------------------------------- GET SET */
  set current (card) {
    if (card instanceof Card) {
      this._current && this._current.deactivate()
      this._current = card
      this._current.activate()
    }
  }
  get current () {
    return this._current
  }

  set next (card) {
    if (card instanceof Card) {
      if (this._next) {
        this._next.isnext = false
      }
      this._next = card
      this._next.isnext = true
    }
  }
  get next () {
    return this._next
  }

  set cards (cards) {
    this._cards = cards
  }
  get cards () {
    return this._cards
  }

  set index (index) {
    if (index >= 0 && index <= this.cards.length - 1) {
      this._index = index
    }
  }
  get index () {
    return this.current ? this._cards.indexOf(this.current) : 0
  }

  set direction (direction) {
    this._direction = direction === DIRECTION_LEFT ? DIRECTION_LEFT : DIRECTION_RIGHT
  }
  get direction () {
    return this._direction
  }
}

export default Tender
