export const forEach = (array, callback, scope) => {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, array[i], i) // passes back stuff we need
  }
}

/**
 * Merges an option object values with a default one if key exists in default
 * @param  {Object} options
 * @return {Object}
 */
export const mergeSettings = (options, defaults = {}) => {
  if (!options) {
    return defaults
  }
  for (const attrName in options) {
    defaults[attrName] = options[attrName]
  }

  return defaults
}

/**
 * Tests if input is a DOMNode
 * @param  {any} input input
 * @return {Boolean}
 */
export const isDomNode = (input) => {
  return input instanceof Element || input instanceof HTMLDocument
}

/**
 * Converts html string to dom node
 * @param  {string} html HTML string to be processed
 * @return {DOMNode}      valid DOMNode
 */
export const htmlToElement = (html) => {
  const template = document.createElement('template')

  // removing extra white spaces
  html = html.trim()
  template.innerHTML = html
  return template.content.firstChild
}

/**
 * Removes HTML content from string
 * @param  {String} str input
 * @return {String}     output
 */
export const removeHtml = (str) => {
  const tmp = document.createElement('div')
  tmp.innerHTML = str
  return tmp.textContent || tmp.innerText
}
