export const TENDER_CLASS = 'Tender'
export const CARD_CLASS = 'Tender__card'
export const ACTIVE_CLASS = '--active'
export const INACTIVE_CLASS = '--inactive'
export const DIRECTION_RIGHT = 'right'
export const DIRECTION_LEFT = 'left'

export const EVENT_CARD_PAN = 'cord-pan'
export const EVENT_CARD_PAN_END = 'cord-pan-end'
export const EVENT_CARD_PAN_SUCCESS = 'cord-pan-success'
export const EVENT_CARD_PAN_RESET = 'cord-pan-reset'
export const EVENT_CARD_TAP = 'cord-tap'

export const EVENT_LOADED = 'loaded'
export const EVENT_LOADING = 'loading'
